/** @format */

import { actions } from '../../reducers/app';

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Transition } from 'react-spring/renderprops';
import styled from 'styled-components';
import { mdiChevronUp, mdiChevronDown } from '@mdi/js';
import { Icon } from '@mdi/react';
import { IconButton } from '@mollybet/ui';
import { withTheme } from 'styled-components';
import { XenaDiv } from '../shared/Common';
import Parlay from '../parlays/Parlay';
import AccaWarning from './AccaWarning';

const AccBarContainer = styled(XenaDiv)`
  position: fixed;
  top: 80px;
  left: 0px;
  height: -moz-calc(100% - 60px);
  height: -webkit-calc(100% - 60px);
  height: -o-calc(100% - 60px);
  height: calc(100% - 60px);
  height: -webkit-calc(100% - 60px);
  width: 100vw;
  z-index: 400;
  background: ${(props) => props.theme.xenaColorTwo};
  color: ${(props) => props.theme.fontColorContrast};

  .acc-builder-header {
    position: relative;
    top: 0px;
    left: 0px;
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 500;
    background: ${(props) => props.theme.xenaColorGradient};
    color: ${(props) => props.theme.fontColorContrast};
    padding-left: 5px;
    padding-right: 10px;
  }

  .acc-builder-body {
    position: relative;
    top: 0px;
    left: 0px;
    height: -webkit-calc(100% - 40px);
    /* Firefox */
    height: -moz-calc(100% - 40px);
    /* WebKit */
    height: -webkit-calc(100% - 40px);
    /* Opera */
    height: -o-calc(100% - 40px);
    /* Standard */
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
`;

const AccExpandButton = styled.div`
  background: ${(props) => props.theme.xenaColorHighlight};
  position: fixed;
  bottom: 0px;
  right: 50%;
  left: 50%;
  margin-left: -32px;
  z-index: 1000;
  height: 44px;
  width: 84px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StlyedIconButton = styled(IconButton)`
  color: ${(props) => props.theme.fontColorContrast};
  fill: ${(props) => props.theme.fontColorContrast};
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.fontColorContrast};
  fill: ${(props) => props.theme.fontColorContrast};
`;

const SameColourStyledIcon = styled(Icon)`
  color: ${(props) => props.theme.fontColorBlack};
  fill: ${(props) => props.theme.fontColorBlack};
`;

const AccBar = (props) => {
  const toggle = () => {
    props.actions.toggleAccBar();
  };

  const expandAccBar = () => {
    props.actions.expandAccBar();
  };

  const numOfEntries = props?.parlay?.get('entries');
  const accSize = numOfEntries ? numOfEntries.size : 0;
  if (props.expanded) {
    return (
      <AccBarContainer>
        <div className="acc-builder-header">
            <FormattedMessage id="trade.parlay.accaBuilder" defaultMessage="Acca builder" />
            <StlyedIconButton className="chevron-icon" onClick={toggle}>
              <StyledIcon path={mdiChevronDown} />
            </StlyedIconButton>
        </div>
        <XenaDiv background={'xenaColorTwo'} className="acc-builder-body">
          <Parlay parlayId={props.accId} />
        </XenaDiv>
      </AccBarContainer>
    )
  } else {
    return (
      <>
        <AccaWarning parlayId={props.accId} />
        <AccExpandButton onClick={expandAccBar}>
          <SameColourStyledIcon path={mdiChevronUp} />
          <span>{accSize > 0 ? `${accSize}xAcca` : 'Acca'}</span>
        </AccExpandButton>
      </>
    )
  }
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// turn state of combined reducers into state required by component
const mapStateToProps = (state, ownProps) => {
  const accumulatorId = state.getIn(['parlays', 'parlays']).keySeq().first();
  return {
    accId: accumulatorId,
    parlay: state.getIn(['parlays', 'parlays', accumulatorId], null),
    expanded: state.getIn(['ui', 'accBarOpen'], false),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(AccBar)));
